
import Vue from "vue"
import mapboxgl from "mapbox-gl"
import { getScreenWidth } from "~/scripts/useful-functions"
import { mapGetters } from "vuex"
import { GET_MAP_LOCATION } from "~/store/location-map/getters"

interface PinData {
  id: number
  lat: number
  lng: number
  imageUrl: string
  textLine1: string
  textLine2: string
  textLine3: string
  stars: number
  startOpen?: boolean
}

const MOVE_TO_PIN_DURATION = 1000
const PIN_POPUP_DELAY = 1500
const INITIAL_ZOOM = 13

export default Vue.extend({
  name: "MapBoxLocalMovesComponent",
  props: {
    block: {
      required: true,
      type: Object as () => Block
    }
  },
  data() {
    return {
      accessToken: this.$config.mapBoxKey,
      map: null as mapboxgl.Map | null,
      markerIconUrl:
        "https://anyvan-map-images.s3.eu-west-1.amazonaws.com/local-moves-pin-orange.svg"
    }
  },
  methods: {
    initializeMap(): void {
      const bounds = new mapboxgl.LngLatBounds()
      this.pins.forEach(pin => {
        bounds.extend([pin.lng, pin.lat])
      })

      mapboxgl.accessToken = this.$config.mapBoxKey
      this.map = new mapboxgl.Map({
        container: this.$refs.mapContainer as HTMLElement,
        style: "mapbox://styles/mapbox/streets-v11",
        attributionControl: false,
        bounds,
        maxZoom: INITIAL_ZOOM + 1,
        minZoom: INITIAL_ZOOM - 1,
        fitBoundsOptions: {
          padding: { top: 0, bottom: 0, left: this.getLeftPadding(), right: 0 },
          maxZoom: INITIAL_ZOOM
        }
      })

      this.map.on("load", () => {
        if (!this.map) return
        this.addPinsToMap()
        this.setMapBoundaries()

        // hide labels
        this.map.setLayoutProperty("country-label", "visibility", "none")
        this.map.setLayoutProperty("state-label", "visibility", "none")
        this.map.setLayoutProperty("settlement-label", "visibility", "none")
        this.map.setLayoutProperty("road-label", "visibility", "none")
        this.map.setLayoutProperty("poi-label", "visibility", "none")
        this.map.setLayoutProperty("road-label-motorway", "visibility", "none")
        this.map.setLayoutProperty("road-label-major", "visibility", "none")
        this.map.setLayoutProperty("road-number-shield", "visibility", "none")
        this.map.setLayoutProperty("transit-station-label", "visibility", "none")
      })

      this.map.scrollZoom.disable()
      this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), "bottom-right")
    },
    addPinsToMap(): void {
      if (!this.map) return

      this.pins.forEach(pin => {
        // create a custom marker element
        const markerElement = document.createElement("div")
        markerElement.className = "av-marker"

        // create an image element to hold the SVG
        const img = document.createElement("img")
        img.src = this.markerIconUrl
        img.alt = "Map marker"
        markerElement.appendChild(img)

        markerElement.addEventListener("click", () => {
          this.centerMapOnPin(pin)
        })

        // create the star rating div
        const starsDiv = this.createStarRating(pin.stars)

        const popup = new mapboxgl.Popup({
          offset: 25,
          className: "av-popup",
          anchor: "bottom"
        }).setHTML(
          `<div class="av-popup-container"><img src="${pin.imageUrl}"/><div class="flex flex-col gap-2 text-sm whitespace-nowrap"><div>${pin.textLine1}</div><div>${pin.textLine2}</div><div>${pin.textLine3}</div>${starsDiv.outerHTML}</div></div>`
        )

        // create a marker with the custom element
        new mapboxgl.Marker(markerElement)
          .setLngLat([pin.lng, pin.lat])
          .setPopup(popup)
          .addTo(this.map as mapboxgl.Map)

        if (pin.startOpen) {
          // delay by 3 seconds
          setTimeout(() => {
            popup.addTo(this.map!)
          }, PIN_POPUP_DELAY)
        }
      })
    },
    centerMapOnPin(pin: PinData): void {
      if (!this.map) return

      const bounds = new mapboxgl.LngLatBounds()
      bounds.extend([pin.lng, pin.lat])

      this.centerMapOnBounds(bounds)
    },
    centerMapOnBounds(
      bounds: mapboxgl.LngLatBounds,
      animationDuration: number = MOVE_TO_PIN_DURATION
    ): void {
      if (!this.map) return

      this.map.fitBounds(bounds, {
        maxZoom: 13,
        duration: animationDuration,
        padding: { top: 0, bottom: 0, left: this.getLeftPadding(), right: 0 }
      })
    },
    calculatePinsBounds(): mapboxgl.LngLatBounds {
      const bounds = new mapboxgl.LngLatBounds()
      this.pins.forEach(pin => {
        bounds.extend([pin.lng, pin.lat])
      })
      return bounds
    },
    setMapBoundaries(): void {
      if (!this.map) return
      const currentBounds = this.map.getBounds()
      if (!currentBounds) return

      // calculate the width and height of the current view
      const width = currentBounds.getEast() - currentBounds.getWest()
      const height = currentBounds.getNorth() - currentBounds.getSouth()

      // calculate new bounds that extend the current view in all directions
      const maxBounds = new mapboxgl.LngLatBounds(
        [currentBounds.getWest() - width, currentBounds.getSouth() - height],
        [currentBounds.getEast() + width, currentBounds.getNorth() + height]
      )

      // set the maximum bounds
      this.map.setMaxBounds(maxBounds)
    },
    createStarRating(rating) {
      const starsDiv = document.createElement("div")
      starsDiv.className = "flex gap-2"
      const fullStars = Math.floor(rating)
      // const hasHalfStar = rating % 1 !== 0

      // add full stars
      starsDiv.innerHTML =
        "<img class='star' src='https://anyvan-map-images.s3.eu-west-1.amazonaws.com/star.svg'/>".repeat(
          fullStars
        )

      // // add half star
      // if (hasHalfStar) {
      //   starsDiv.innerHTML += "★" // todo make this the image from figma
      // }

      return starsDiv
    },
    getLeftPadding() {
      const screenWidth = getScreenWidth()

      if (screenWidth && screenWidth >= 1200) {
        const map = this.$refs.mapContainer as HTMLElement
        return map.clientWidth / 2
      }
      return 0
    }
  },
  computed: {
    ...mapGetters("location-map", {
      pins: GET_MAP_LOCATION
    })
  },
  mounted() {
    if (this.pins.length > 0) {
      this.initializeMap()
    }
  }
})
