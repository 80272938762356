
import vue from "vue"
import TrustpilotBaseComponent from "~/mixins/trustpilotBase"

export default vue.extend({
  name: "TrustPilotWidget",
  mixins: [TrustpilotBaseComponent],
  computed: {
    defaultValue() {
      const trustPilotDefaultCount = {
        en: 146950,
        es: 572,
        fr: 243,
        de: 63,
        it: 3
      }
      if (!this.$i18n.locale) {
        return trustPilotDefaultCount.en
      }
      return trustPilotDefaultCount[this.$i18n.locale]
    },
    getLocaleLink() {
      const trustPilotLinks = {
        en: "https://uk.trustpilot.com/review/anyvan.com",
        es: "https://es.trustpilot.com/review/anyvan.es",
        fr: "https://fr.trustpilot.com/review/anyvan.fr",
        de: "https://de.trustpilot.com/review/anyvan.de",
        it: "https://it.trustpilot.com/review/anyvan.it"
      }
      if (!this.$i18n.locale) {
        return trustPilotLinks.en
      }
      return trustPilotLinks[this.$i18n.locale]
    }
  }
})
